import { render, staticRenderFns } from "./ListRole.vue?vue&type=template&id=0ad65e90&"
import script from "./ListRole.vue?vue&type=script&lang=ts&"
export * from "./ListRole.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/workspace/SPP/spp-erp-fe-staging/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ad65e90')) {
      api.createRecord('0ad65e90', component.options)
    } else {
      api.reload('0ad65e90', component.options)
    }
    module.hot.accept("./ListRole.vue?vue&type=template&id=0ad65e90&", function () {
      api.rerender('0ad65e90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user-management/ListRole.vue"
export default component.exports